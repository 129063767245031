html {
  height: 100%;
} 

body {
  min-height: 100%
}

.App {
  position: absolute;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.3)) , url("./images/bar.jpg");
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calc-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 0;
  background-image: url("./images/beer.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 575px;
  width: 100%;
}

h1 {
  margin: 60px 0 72px 0;
  font-size: 27px;
  color: #8d6206;
}

.field {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  align-items: center;
}

.field span {
  background-color: #ffeeb7;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  margin-bottom: 5px;
  max-width: 200px;
  border: 2px solid #9c7801;
}

.field.result {
  max-width: 200px;
  margin: 35px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
}

select, input {
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  border: 2px solid #9c7801;
  background-color: #ffeeb7;
  -webkit-appearance: none;
  text-align: center;
  text-align: -webkit-center;
  max-width: 217px;
}

input {
  width: 134px;
}

.clear-button {
  position: absolute;
  right: 9px;
  top: 6px;
  font-size: 20px;
  cursor: pointer;
}

.quantity-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.info {
  position: absolute;
  bottom: 7px;
  right: 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.info.white {
  background-image: url("./images/info-white.svg");
}

.info.black {
  background-image: url("./images/info-black.svg");
}

.close-popup {
  position: absolute;
  top: -15px;
  right: 5px;
  font-size: 55px;
  cursor: pointer;
}

.popup {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  padding: 20px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.popup-content .col {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup-content .col-1 {
  padding-top: 20px;
  align-items: center;
}

.the-man-the-legend {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  max-width: 250px;
  max-height: 250px;
}

.pull-right {
  float: right;
}

.mute, .unmute {
  position: absolute;
  bottom: 4px;
  left: 6px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
}

.mute.white {
  background-image: url("./images/mute-white.svg");
}

.unmute.white {
  background-image: url("./images/unmute-white.svg");

}

.mute.black {
  background-image: url("./images/mute-black.svg");
}

.unmute.black {
  background-image: url("./images/unmute-black.svg");
}

.copyright {
  position: absolute;
  color: #ffffff;
  font-size: 7px;
  bottom: 6px;
}

.drink-responsibly {
  position: absolute;
  color: #000000;
  font-size: 7px;
  bottom: 6px;
}

 .text-search{
  width: 100%;
  font-size: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.text-search input{
  padding: 10px;
  width: 200px;
}

.text-search ul::before{
  content: '';
}
  
.text-search ul{
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  background-color: #ffeeb7;
  width: 217px;
  max-height: 290px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  border: 2px solid #9c7801;
  position: absolute; 
  top: 42px;
  z-index: 1;
}
  
.text-search li{
  padding: 10px 5px ;
  cursor: pointer;
  border-top: 1px solid grey;
}

.clear-button {
  cursor: pointer;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #2b2b2b;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #2b2b2b;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #2b2b2b;
}

/* larger than iphone */
@media screen and (min-width: 768px) {
  .calc-wrapper {
    height: 600px;
  }
  h1 {
    margin: 65px 0 75px 0;
  }
  .info {
    bottom: 15px;
    right: 15px;
  }
  .mute, .unmute {
    bottom: 12px;
    left: 16px;
  }
  .popup-content {
    flex-direction: row;
  }
  .popup-content .the-man-the-legend {
    width: 250px;
  }
  .copyright, .drink-responsibly {
    bottom: 10px;
  }
 }

 /* smaller than iphone */
@media screen and (max-width: 374px) {
  .calc-wrapper {
    height: 500px;
  }
  h1 {
    margin: 53px 0 70px 0;
    font-size: 24px;
  }
  .field span {
    font-size: 10px;
    max-width: 160px;
  }
  select, input {
    font-size: 10px;
  }
  .field.result {
    margin: 15px 0 0 0;
    height: 180px;
  }
  .text-search input {
    width: 187px;
  }
  .text-search ul {
    top: 40px;
    width: 207px;
  }
  .close-popup {
    right: 0px;
    font-size: 45px;
  }
  .popup-content .col-1 {
    padding-bottom: 0;
  }
 }